import { GET_CART } from "./queries/getCart"
import { storefrontClient } from "./storefrontClient"

type GetCartResponse = {
  cart: {
    id: string
    totalQuantity: number
  }
}

type GetCartVariables = {
  id: string
}

export const getCartId = () => {
  const allCookies = document.cookie.split("; ")
  const ligonierCookie = allCookies.find((cookieStr) =>
    /^ligonierCartId=/i.test(cookieStr),
  )
  return ligonierCookie?.split("=")[1]
}

export const getCart = async (id: string) => {
  try {
    const response = await storefrontClient.request<
      GetCartResponse,
      GetCartVariables
    >(GET_CART, {
      id,
    })
    return response
  } catch (error) {
    console.log(`Error fetching cart`, error)
  }
}
