import { GraphQLClient } from "graphql-request"

const env = typeof window === "undefined" ? process.env : window.ENV

export const url = env?.PUBLIC_SHOPIFY_STOREFRONT_URL as string
export const storefrontAccessToken =
  env?.PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN as string

const storefrontClient: GraphQLClient = new GraphQLClient(url, {
  headers: {
    "Content-Type": "application/json",
    "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
  },
})

export { storefrontClient }
