import type { ChangeEvent, SyntheticEvent } from "react"
import { useState, useCallback } from "react"
import { Combobox } from "@headlessui/react"
import debounce from "lodash/debounce.js"
import { twMerge } from "tailwind-merge"
import { SuggestedSearch, ListCard, SearchInput, getListCardProps } from "ui"
import type {
  AlgoliaHit,
  AlgoliaQuerySuggestion,
  AlgoliaRecord,
  ImageRenderer,
  SearchInputVariant,
} from "ui"
import { useSearch } from "@hooks/useSearch"

export interface SearchProps {
  containerClassName?: string
  className?: string
  inputVariant?: SearchInputVariant
  placeholder?: string
  dropdownClassName?: string
  querySuggestionsIndex: string
  searchResultsIndex: string
  renderItemImage: ImageRenderer
  renderPersonItemImage: ImageRenderer
  renderProductItemImage: ImageRenderer
  renderAvatarImage: ImageRenderer
  onSelectSearchResult?: (
    selectedItem: AlgoliaRecord,
    calculatedSearchInputVal: string,
  ) => void
  onSearch?: (query: string) => void
}

export function Search({
  className,
  inputVariant,
  placeholder = "Start your search here",
  dropdownClassName,
  querySuggestionsIndex,
  searchResultsIndex,
  renderItemImage,
  renderPersonItemImage,
  renderProductItemImage,
  renderAvatarImage,
  onSearch,
  onSelectSearchResult,
}: SearchProps) {
  const imageRenderers = {
    renderItemImage,
    renderPersonItemImage,
    renderProductItemImage,
    renderAvatarImage,
  }

  const [selectedOption, setSelectedOption] = useState<AlgoliaHit | null>(null)
  const [inputVal, setInputVal] = useState("")

  const { search, searchResults, querySuggestions } = useSearch({
    searchResultsIndexName: searchResultsIndex,
    querySuggestionsIndexName: querySuggestionsIndex,
  })

  const getSearchInputDisplayValue = (
    selectedDropdownOption: AlgoliaHit | null | undefined,
  ): string => {
    if (!selectedDropdownOption) return inputVal

    const selectedQuerySuggestion =
      selectedDropdownOption as AlgoliaQuerySuggestion
    const selectedRecord = selectedDropdownOption as AlgoliaRecord

    if (selectedQuerySuggestion.query) return selectedQuerySuggestion.query

    const { heading } = getListCardProps(selectedRecord, imageRenderers)

    return heading || inputVal
  }

  const handleComboboxChange = (item: AlgoliaHit) => {
    setSelectedOption(item)

    const querySuggestion = item as AlgoliaQuerySuggestion

    if (querySuggestion.query) {
      onSearch?.(querySuggestion.query)
    } else {
      const displayValue = getSearchInputDisplayValue(item)

      onSelectSearchResult?.(item as AlgoliaRecord, displayValue)
    }
  }

  // https://rajeshnaroth.medium.com/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
  // look at this here for the explanation of useRef/useCallback with debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDebounced = useCallback(debounce(search, 500), [])

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const query = e.target.value

    setInputVal(query)
    if (selectedOption) setSelectedOption(null)

    searchDebounced(query)
  }

  const hasOptions = Boolean(
    querySuggestions.length || searchResults.hits.length,
  )

  return (
    <Combobox value={selectedOption} onChange={handleComboboxChange}>
      {({ open, activeIndex }) => (
        <>
          <SearchInput.Wrapper className={className} variant={inputVariant}>
            <Combobox.Input
              as={SearchInput.Input}
              placeholder={placeholder}
              onPressEnter={(_e: SyntheticEvent) => {
                // Initiate search when Enter key is pressed, but ONLY if no dropdown
                // option is currently highlighted AND the user hasn't just selected one
                // We want to avoid calling this at the same time as handleComboboxChange()
                if (activeIndex === null && !selectedOption)
                  onSearch?.(inputVal)
              }}
              onChange={handleInputChange}
              displayValue={getSearchInputDisplayValue}
            />
            <SearchInput.Button
              hasSearchInput={!!inputVal}
              onClick={() => onSearch?.(inputVal)}
            />
          </SearchInput.Wrapper>
          {open && hasOptions && (
            <Combobox.Options
              static
              as="div"
              className={twMerge("pl-0", dropdownClassName)}
            >
              <SuggestedSearch query={inputVal}>
                {querySuggestions.length > 0 && (
                  <SuggestedSearch.QuerySuggestions>
                    {querySuggestions.map((qs, i) => (
                      <Combobox.Option
                        key={`query-suggestion-${qs.query}-${i}`}
                        value={qs}
                      >
                        {({ active }) => (
                          <SuggestedSearch.QuerySuggestion
                            text={qs.query}
                            active={active}
                            tag="div"
                          />
                        )}
                      </Combobox.Option>
                    ))}
                  </SuggestedSearch.QuerySuggestions>
                )}
                {searchResults.hits.length > 0 && (
                  <SuggestedSearch.Items>
                    {searchResults.hits.map((result, i) => (
                      <Combobox.Option
                        className="list-none"
                        key={`search-result-${i}`}
                        value={{
                          ...result,
                          position: i + 1,
                          queryID: searchResults.queryID,
                        }} // need these extra values for CTRs
                      >
                        {({ active }) => {
                          const propsFromAlgoliaRecord = getListCardProps(
                            result,
                            imageRenderers,
                          )

                          return (
                            <ListCard
                              {...propsFromAlgoliaRecord}
                              borderless
                              active={active}
                              tag="div"
                            />
                          )
                        }}
                      </Combobox.Option>
                    ))}
                  </SuggestedSearch.Items>
                )}
              </SuggestedSearch>
            </Combobox.Options>
          )}
        </>
      )}
    </Combobox>
  )
}
