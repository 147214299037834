import { gql } from "graphql-request"

export const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      id
      totalQuantity
    }
  }
`
