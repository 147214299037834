import { useFlags } from "launchdarkly-react-client-sdk"
import { useContext, useMemo } from "react"
import type { SearchClient } from "algoliasearch"
import algolia from "algoliasearch"
import type { ContentfulAlert, NavigationSection, SearchProps } from "ui"
import {
  AlertBar,
  Footer,
  ResponsiveImage,
  Navbar,
  Button,
  footerLanguageLocales,
  legalLinks,
  socialLinks,
  storeContact,
  storeLinks,
  tertiaryTitle,
  algoliaIdxs,
  getUrlFromAlgoliaRecord,
  renderAvatarImage,
  renderItemImage,
  renderProductItemImage,
  Link,
} from "ui"
import {
  contentToHeaderSections,
  getLinkRenderer,
} from "~/utils/contentful/navigationHelpers"
import SearchProviderWrapper, {
  transformSearchHits,
} from "./SearchProviderWrapper"
import type { EventItemForHeaderFooterFragment } from "~/generated/contentful"
import type { SearchProviderProps } from "./SearchProvider"
import { convertToCareersUrl } from "../utils/url"
import { Search } from "./Search"
import { Form, Outlet, useMatches, useNavigate } from "@remix-run/react"
import type { OAuth2Profile } from "~/utils/AzureAADOAuth2BaseStrategy"
import { analytics } from "~/utils/segment.client"
import type { ContentfulSubfooter } from "~/utils/contentful/subfooter"
import { algoliaAnalytics } from "~/utils/search"
import { XStateContext } from "~/contexts/xStateContext"
import { useSelector } from "@xstate/react"
import type { ContentfulNavigationSection } from "~/utils/contentful/types"

type LayoutProps = {
  alerts: {
    site: Array<ContentfulAlert>
  }
  ENV: Record<string, string | undefined>
  headerNavigationSections: Array<ContentfulNavigationSection>
  footerSections: Array<NavigationSection>
  subfooter: ContentfulSubfooter
  events: Array<EventItemForHeaderFooterFragment>
  user: OAuth2Profile | null
}

export const Layout = (props: LayoutProps) => {
  const {
    alerts,
    ENV,
    headerNavigationSections,
    events,
    user,
    subfooter,
    footerSections,
  } = props
  const navigate = useNavigate()
  const matches = useMatches()
  const xStateRefs = useContext(XStateContext)
  const cartTotalItems = useSelector(
    xStateRefs.cartActorRef,
    ({ context }) => context.totalQuantity,
  )

  const useIsAccountDashboardAvailable = (): boolean => {
    const flags = useFlags()

    return flags.myAccountDashboard === "myAccountDashboardOn"
  }

  const { pathname } = matches[matches?.length - 1]

  const handleEmailSignup = (email: string) => {
    analytics.track("Sign Up", { email })
  }

  const baseUrl = ENV.ACCOUNT_REDIRECT_URL

  const menuItems = [
    {
      id: "my account",
      renderElement: <Link to={`${baseUrl}/dashboard/`}>My Account</Link>,
    },
    {
      id: "my library",
      renderElement: (
        <Link to={`${baseUrl}/dashboard/learning-library/`}>My Library</Link>
      ),
    },
    {
      renderElement: (
        // for some reason using AuthAction is causing a ref forwarding error
        // so I just used the remix component here instead for a quick fix.
        // It's likely because Form does use forwardRef, so using inside of another component
        // may be causing some type of weird conflict. We could try to manually pass a null
        // ref and see if that fixes it, but this works for now. - Taylor
        <Form action={`/auth/logout?redirectTo=${pathname}`} method="post">
          <button>Sign out</button>
        </Form>
      ),
      id: "logout",
    },
  ]

  const breadcrumbs =
    matches[matches.length - 1].pathname !== "/"
      ? [
          {
            label: "Postings",
            link: "/postings",
          },
        ]
      : []

  const headerSections = contentToHeaderSections({
    sections: headerNavigationSections,
    events,
  })

  const searchClient = useMemo<SearchClient>(() => {
    const algoliaClient = algolia(
      ENV?.ALGOLIA_APP_ID || "",
      ENV?.ALGOLIA_API_KEY || "",
    )

    // Patch client to disable analytics for searches w/ no query (e.g. category pages)
    // Also set facetingAfterDistinct=true. We split Algolia records with large
    // fields into multiple records, each containing a chunk of the field,
    // and the facet counts include each matching chunk without this
    // setting, which makes the counts too high.
    return {
      ...algoliaClient,
      search(requests) {
        const newRequests = requests.map((request) => {
          // Disable analytics for the request if no query
          if (request.params && !request.params.query?.length) {
            return {
              ...request,
              params: {
                ...request.params,
                analytics: false,
                facetingAfterDistinct: true,
              },
            }
          } else {
            return {
              ...request,
              params: {
                ...request.params,
                facetingAfterDistinct: true,
              },
            }
          }
        })

        return algoliaClient.search(newRequests)
      },
    }
  }, [ENV])

  const initQS = typeof window !== "undefined" ? location.search : ""

  const searchProviderProps: SearchProviderProps = {
    searchClient: searchClient,
    sortIndexes: {
      newest: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.new}`,
      oldest: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.old}`,
      featured: ENV?.ALGOLIA_ENV,
      alphabetical: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.alphabetical}`,
      price_asc: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.ascPrice}`,
      price_desc: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.descPrice}`,
    },
    defaultSort: "newest",
    queryString: initQS,
    transformSearchHits,
  }

  const searchProps: SearchProps = {
    placeholder: "Search",
    querySuggestionsIndex:
      `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.querySuggestions}` || "",
    searchResultsIndex: ENV?.ALGOLIA_ENV || "",
    onSelectSearchResult: (item) => {
      const { urlFromAlgolia, internal } = getUrlFromAlgoliaRecord(
        item,
        "Careers",
      )

      if (item.queryID) {
        try {
          algoliaAnalytics("clickedObjectIDsAfterSearch", {
            index: ENV?.ALGOLIA_ENV || "",
            eventName: "Search Item Clicked",
            queryID: item.queryID,
            objectIDs: [item.objectID],
            positions: [item.position],
          })
        } catch (err) {
          console.log(`Algolia Analytics Error`, err)
        }
      }
      // If we have an internal url (e.g. "/2024-national-conference", we don't need to convert anything. Just navigate)
      const url = internal
        ? urlFromAlgolia
        : convertToCareersUrl(urlFromAlgolia)

      if (internal) {
        navigate(url)
      } else {
        window.location.href = url
      }
    },
    onSearch: (query) => {
      window.location.href = `https://www.ligonier.org/search?query=${query}`
    },
    renderItemImage: renderItemImage,
    renderPersonItemImage: renderItemImage,
    renderProductItemImage: renderProductItemImage,
    renderAvatarImage: renderAvatarImage,
    SearchComponent: Search,
  }

  return (
    <div>
      <AlertBar alerts={alerts} hideAlertBar={ENV.HIDE_ALERT_BANNERS} />
      <SearchProviderWrapper {...searchProviderProps}>
        <Navbar variant="cream" className="border-b border-tint-edge">
          <Navbar.Left>
            <Navbar.Menu
              sections={headerSections}
              onSubmitEmailSignup={handleEmailSignup}
              subfooter={subfooter}
            />
            <Navbar.Home
              renderLink={getLinkRenderer("https://www.ligonier.org")}
            />
          </Navbar.Left>
          <Navbar.Search {...searchProps} containerClassName="hidden xs:flex" />
          <Navbar.Right>
            {cartTotalItems > 0 && (
              <Navbar.Cart
                renderLink={getLinkRenderer("https://store.ligonier.org/cart")}
                itemCount={cartTotalItems}
                className="hidden xs:flex"
              />
            )}
            {useIsAccountDashboardAvailable() && (
              <Navbar.Auth
                loggedIn={user ? true : false}
                menuItems={menuItems}
                menuItemClassName="right-5 md:right-7"
                menuIconClassName="fill-primary-600 group-hover:fill-cool-copy"
                renderJSX={
                  <Form action="/auth/login" method="post">
                    <Button
                      icon="40-userAvatar"
                      iconClass="fill-primary-600 group-hover:fill-cool-copy"
                      className="flex"
                    />
                  </Form>
                }
              />
            )}

            <Navbar.Donate renderLink={getLinkRenderer("/donate")} />
          </Navbar.Right>
        </Navbar>
      </SearchProviderWrapper>

      <Outlet />
      <Footer
        renderLink={(props) => {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <Link
              {...props}
              data-testid={props.testId}
              to={props.url as string}
            />
          )
        }}
        languages={footerLanguageLocales}
        languageClassName="list-none"
        legalLinks={legalLinks}
        renderStoreImage={(props) => (
          <div {...props}>
            <ResponsiveImage
              src="/payment-cards.svg"
              alt="Accepted Payment Cards :: Visa, American Express, Discover, Mastercard, Apple Pay, Google Pay"
              descriptor="x"
              minWidth={150}
              width="150px"
              height="15px"
            />
          </div>
        )}
        sections={footerSections}
        socialLinks={socialLinks}
        storeContact={storeContact}
        storeLinks={storeLinks}
        storeClassName="list-none"
        tertiaryTitle={tertiaryTitle}
        onSubmitEmailSignup={handleEmailSignup}
        subfooter={subfooter}
        breadcrumbs={[
          {
            label: "Home",
            link: "https://www.ligonier.org",
          },
          {
            label: "Careers",
            link: "/",
          },
          ...breadcrumbs,
        ]}
      />
    </div>
  )
}
