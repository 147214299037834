import type { ParsedQs } from "qs"
import qs from "qs"
import omit from "lodash/omit.js"
import type { SearchState, SearchSortOption, SortKey } from "ui"
import type { SearchProviderProps } from "../components/SearchProvider"
import aa from "search-insights"

const env = typeof window === "undefined" ? process.env : window.ENV

const { PUBLIC_ALGOLIA_APP_ID, PUBLIC_ALGOLIA_API_KEY } = env

aa("init", {
  appId: PUBLIC_ALGOLIA_APP_ID,
  apiKey: PUBLIC_ALGOLIA_API_KEY,
  useCookie: true, // Generate a user token and store it in a cookie
})

export const algoliaAnalytics = aa

// UI labels corresponding to the available sort options
export const sortLabels: Record<SortKey, string> = {
  featured: "Relevant",
  recent: "Recent",
  alphabetical: "Alphabetical",
  price_asc: "Price: Low to High",
  price_desc: "Price: High to Low",
  scripture: "Scripture",
  newest: "Newest",
  oldest: "Oldest",
}

export const defaults = {
  hitsPerPage: 48, // TODO: decrease to 24?
  page: 1,
  query: "",
}

const formatStringForURL = (text: string): string =>
  text.split(" ").map(encodeURIComponent).join("+")

interface GetInitialSearchStateInputs {
  parsedQS: ParsedQs
  passthroughQueryStringParams: Array<string>
  sortIndexes: SearchProviderProps["sortIndexes"]
  defaultSortOption: SearchSortOption
  filters: string
}

// Shapes the initial searchState object to use for react-instantsearch
// Uses values from parsed query string along w/ relevant parts of config
export const getInitialSearchState = ({
  parsedQS,
  passthroughQueryStringParams,
  sortIndexes,
  defaultSortOption,
  filters,
}: GetInitialSearchStateInputs) => {
  // Parsed query string in order to get search state for React InstantSearch

  // Parsed query string params corresponding to search facets; all but the
  // ones omitted below are assumed to represent Algolia facets
  const facetsFromQS = omit(parsedQS, [
    "sort",
    "page",
    "limit",
    "query",
    ...passthroughQueryStringParams,
  ]) as Record<string, string>

  // Refinement list object for search state; with multi-value refinements
  // (e.g. "product|sermon") converted into arrays (["product", "sermon"])
  const refinementList = {
    ...Object.entries(facetsFromQS).reduce(
      (acc, [key, val]) => ({ ...acc, [key]: val.split("|") || [] }),
      {},
    ),
  }

  // Try to derive Algolia search index name from query string variable 'sort'
  // Use the default sort option's search index name as fallback
  const sortKeyFromQS = parsedQS.sort as SortKey | undefined
  const searchIndexNameFromQS = sortKeyFromQS && sortIndexes[sortKeyFromQS]
  const searchIndexName = searchIndexNameFromQS || defaultSortOption.value
  const filterProp = filters ? { filters: filters } : {}

  // Combine & shape all data into React InstantSearch searchState object
  return {
    query: parsedQS.query?.toString() || defaults.query,
    page: Number(parsedQS.page) || defaults.page,
    hitsPerPage: Number(parsedQS.limit) || defaults.hitsPerPage,
    refinementList,
    sortBy: searchIndexName,
    configure: {
      clickAnalytics: true,
      ...filterProp,
    },
  }
}

interface CreateQueryStringInputs {
  searchState: SearchState
  sortOptions: Array<SearchSortOption>
  initialIndexName: string
  passthroughParams?: ParsedQs
  defaultSortOption: SearchSortOption
}

// Creates a query string representation of the current search state from
// react-instantsearch; existing "passthrough" query string parameters are
// included if passed
export const createQueryString = ({
  searchState,
  sortOptions,
  initialIndexName,
  passthroughParams,
  defaultSortOption,
}: CreateQueryStringInputs): string => {
  const sortOptionInSearchState = sortOptions.find(
    (index) => index.value === searchState.sortBy,
  )
  const initSortOption = sortOptions.find(
    (index) => index.value === initialIndexName,
  )

  // Params for output query string: basic search-related
  const basicSearchParams = {
    // The reason for this ternary logic is that the query string parsing
    // function (getInitialSearchState) already uses the defaults referenced
    // here if the param isn't in the query string – so there's no point in
    // adding it if it matches the default
    page: searchState.page !== defaults.page ? searchState.page : null,
    query: searchState.query ? formatStringForURL(searchState.query) : null,
    limit:
      searchState.hitsPerPage !== defaults.hitsPerPage
        ? searchState.hitsPerPage
        : null,
    sort:
      searchState.sortBy !== initialIndexName
        ? sortOptionInSearchState?.id
        : defaultSortOption.value !== initialIndexName
        ? initSortOption?.id
        : null,
  }

  // Params for output query string: refinements for search
  // Uses existing refinementList object from react-instantsearch but
  // transforms properties as noted below
  const refinementsParams = Object.entries(
    searchState.refinementList || {},
  ).reduce(
    (acc, [filter, values]) =>
      values
        ? {
            ...acc,
            // Convert multi-value refinements from arrays back to
            // pipe-delimited URL-friendly strings – example:
            // ["product variant", "sermon"] becomes "product+variant|sermon"
            [filter]: Array.isArray(values)
              ? values.map(formatStringForURL).join("|")
              : values,
          }
        : acc,
    {},
  )

  // Full object of params to be uncluded in output query string
  // Include "passthrough" params as they are
  const outputParams = {
    ...passthroughParams,
    ...basicSearchParams,
    ...refinementsParams,
  }

  return qs.stringify(outputParams, {
    encode: false,
    skipNulls: true,
    addQueryPrefix: true,
  })
}
