import { assign, fromPromise, setup } from "xstate"
import { getCartId, getCart } from "~/utils/shopify/cart"

type CartContext = {
  id: string | null
  totalQuantity: number
}

type CartEvents = { type: "LOAD_CART" }

const initContext = {
  id: null,
  totalQuantity: 0,
}

// This is using xstate 5 so it will look a bit different than the previous cart machines.
// Also since careers does not actually do anything with the cart, we only care about fetching
//   the current cart. If there is a current cart then we can grab the total items for it and
//   display that in the header. Otherwise if there is no cart we don't need to really generate
//   a new one since store/www will do that on their own.
// So for now this should make this process a little more simple and clearer to read.

export const CartMachine = setup({
  types: {} as {
    context: CartContext
    events: CartEvents
  },
  actors: {
    loadCart: fromPromise(async () => {
      const cartId = getCartId()
      if (!cartId) {
        console.log("No cart id found")
        throw new Error("No cart id")
      }

      const cartResponse = await getCart(cartId)
      if (cartResponse) {
        return cartResponse.cart
      } else {
        console.log("No cart found")
        throw new Error("No cart found")
      }
    }),
  },
}).createMachine({
  id: "cart-machine",
  context: initContext,
  initial: "loadingCart",
  states: {
    loadingCart: {
      invoke: {
        src: "loadCart",
        id: "invoke_loadCart",
        onDone: {
          target: "final", // we either get the cart or don't, no further actiosn really needed
          actions: assign(({ event }) => event.output),
        },
        onError: {
          target: "final", // we either get the cart or don't, no further actiosn really needed
        },
      },
    },
    final: { type: "final" },
  },
})
