/**
 * Converts cross-domain URLs to careers-compatible URLs
 *
 * Takes an absolute or relative URL belonging to EITHER site (i.e.
 * careers.ligonier.org OR ligonier.org), determines which site it belongs to, and
 * converts it to a URL/path that is compatible with navigation on this site
 *
 * This is needed because the careers & main site share the same Contentful data, so
 * most URLs from Contentful will be relative to ligonier.org
 *
 * URL conversion is as follows:
 *    "/careers/*" => "/*"
 *    "/any-path-not-in-routes" => "https://www.ligonier.org/any-path-not-in-routes"
 *    "https://careers.ligonier.org/*" => "/*"
 */
export const convertToCareersUrl = (url: string): string => {
  // For absolute URLs
  try {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      const { hostname, pathname, search } = new URL(url)

      // For absolute URLs to this site, convert to relative path
      if (hostname === "careers.ligonier.org") {
        return pathname + search
      }

      return url
    }
  } catch (err) {}
  // Error has been caught, so input string is either a relative path or invalid
  // Return home path if input isn't a valid URL/path
  if (!url.startsWith("/")) {
    return "/"
  }

  // Convert paths starting with "/careers" to the part coming after "/careers"
  if (url.match(/^\/careers/)) {
    return url.replace(
      /^\/careers([^\s\n]+)?/,
      (_, pathAfterCareers) => pathAfterCareers || "/",
    )
  }

  // Assume any other relative path is on the main ligonier.org site
  return `https://www.ligonier.org${url}`
}
