import { useState } from "react"
import { useSearchConfig } from "../components/SearchProvider"
import type { AlgoliaQuerySuggestion, AlgoliaRecord } from "ui"

interface UseSearchInput {
  searchResultsIndexName: string
  querySuggestionsIndexName: string
}

interface UseSearchOutput {
  searchResults: { hits: Array<AlgoliaRecord>; queryID?: string }
  querySuggestions: Array<AlgoliaQuerySuggestion>
  search(query: string): Promise<void>
  clearSearchResults(): void
}

export const useSearch = ({
  searchResultsIndexName,
  querySuggestionsIndexName,
}: UseSearchInput): UseSearchOutput => {
  const [searchResults, setSearchResults] = useState<{
    hits: Array<AlgoliaRecord>
    queryID?: string
  }>({ hits: [] })
  const [querySuggestions, setQuerySuggestions] = useState<
    Array<AlgoliaQuerySuggestion>
  >([])

  const searchConfig = useSearchConfig()

  const { searchClient } = searchConfig.config

  const search = async (query: string) => {
    if (!query) {
      setSearchResults({ hits: [] })
      setQuerySuggestions([])

      return
    }

    // Index for dropdown search results (i.e. suggested resources)
    const searchResultsIndex = searchClient.initIndex(searchResultsIndexName)

    // Index for dropdown query suggestions (i.e. search term autocomplete)
    const querySuggestionsIndex = searchClient.initIndex(
      querySuggestionsIndexName,
    )

    // Query suggestions (autocomplete)
    const querySuggestionsResponse =
      await querySuggestionsIndex.search<AlgoliaQuerySuggestion>(query, {
        hitsPerPage: 5,
      })

    // Search results (suggested resources)
    const searchResultsResponse =
      await searchResultsIndex.search<AlgoliaRecord>(query, {
        hitsPerPage: 5,
        attributesToRetrieve: [
          "type",
          "fields",
          "title",
          "primaryVariation",
          "handle",
        ],
        clickAnalytics: true,
      })

    if (searchResultsResponse.hits.length) {
      setSearchResults({
        hits: searchResultsResponse.hits,
        queryID: searchResultsResponse.queryID,
      })
    }

    if (querySuggestionsResponse.hits.length) {
      setQuerySuggestions(querySuggestionsResponse.hits)
    }
  }

  const clearSearchResults = (): void => setSearchResults({ hits: [] })

  return {
    searchResults,
    querySuggestions,
    search,
    clearSearchResults,
  }
}
