import type { FunctionComponent } from "react"
import { useMemo } from "react"
import type { SearchClient } from "algoliasearch"
import algolia from "algoliasearch"
import type {
  TransformSearchHitsCallback,
  SearchProviderProps,
} from "./SearchProvider"
import { SearchProvider } from "./SearchProvider"
import { useEnv } from "@hooks/useEnv"
import { algoliaIdxs, getProductsMap } from "ui"

export const transformSearchHits: TransformSearchHitsCallback = async (
  rawSearchHits,
) => {
  const searchHits = []
  const productHandles = []

  // First we grab all the handles in this page as well as the search hits
  for (const hit of rawSearchHits) {
    if (hit.type !== "product") continue

    const { handle } = hit.primaryVariation
    productHandles.push(handle)
    searchHits.push(hit)
  }

  // We now fetch the products map via one endpoint
  // this takes an array of product handles and returns a map
  // e.g { [some-handle]: SomeProductData, ... }
  const productsMap = await getProductsMap(productHandles)
  // Finally we only want to pass along searchHits that have a product present in
  // the productsMap so we don't have empty products on the results page
  // this leads to errors where clicking on such a search result will break the PDP page
  const filteredSearchHits = searchHits.filter((hit) => {
    const { handle } = hit.primaryVariation
    const isHitPresentInProductsMap = productsMap && productsMap[handle]
    // only return searchHits that are present in the products map
    return isHitPresentInProductsMap
  })

  return { hits: filteredSearchHits, customData: productsMap }
}

export type SearchProviderWrapperProps = Partial<SearchProviderProps>

export const SearchProviderWrapper: FunctionComponent<
  React.PropsWithChildren<SearchProviderWrapperProps>
> = ({ children, queryString, ...restProps }) => {
  const ENV = useEnv()
  // init load is SSR so use queryString prop
  // which comes from root loader at the top which
  // is SSR. Otherwise use the window.location.search
  const initQS = typeof window !== "undefined" ? location.search : queryString

  const searchClient = useMemo<SearchClient>(() => {
    const algoliaClient = algolia(
      ENV?.ALGOLIA_APP_ID || "",
      ENV?.ALGOLIA_API_KEY || "",
    )

    // Patch client to disable analytics for searches w/ no query (e.g. category pages)
    // Also set facetingAfterDistinct=true. We split Algolia records with large
    // fields into multiple records, each containing a chunk of the field,
    // and the facet counts include each matching chunk without this
    // setting, which makes the counts too high.
    return {
      ...algoliaClient,
      search(requests) {
        const newRequests = requests.map((request) => {
          // Disable analytics for the request if no query
          if (request.params && !request.params.query?.length) {
            return {
              ...request,
              params: {
                ...request.params,
                analytics: false,
                facetingAfterDistinct: true,
              },
            }
          } else {
            return {
              ...request,
              params: {
                ...request.params,
                facetingAfterDistinct: true,
              },
            }
          }
        })

        return algoliaClient.search(newRequests)
      },
    }
  }, [ENV?.ALGOLIA_APP_ID, ENV?.ALGOLIA_API_KEY])

  const searchProviderProps: SearchProviderProps = {
    searchClient,
    sortIndexes: {
      newest: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.new}`,
      oldest: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.old}`,
      featured: ENV?.ALGOLIA_ENV,
      alphabetical: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.alphabetical}`,
      price_asc: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.ascPrice}`,
      price_desc: `${ENV?.ALGOLIA_ENV}_${algoliaIdxs.descPrice}`,
    },
    defaultSort: "newest",

    queryString: initQS,
    transformSearchHits,
    ...restProps,
  }

  return <SearchProvider {...searchProviderProps}>{children}</SearchProvider>
}

export default SearchProviderWrapper
