function hasDST(date = new Date()) {
  const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()

  return Math.max(january, july) !== date.getTimezoneOffset()
}

const isFridayEST = (): boolean => {
  const currentDate = new Date()
  const offset = hasDST(currentDate) ? -4 : -5 // EST is GMT-5, EDT is GMT-4
  const utc = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000
  const est = new Date(utc + 3600000 * offset)

  const estDate = est.toDateString()

  return estDate.startsWith("Fri")
}

export default isFridayEST
