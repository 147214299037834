import React, { createContext } from "react"
import { useActorRef } from "@xstate/react"
import { CartMachine } from "~/machines/cart.machine"
import type { Actor } from "xstate"

export const XStateContext = createContext({
  cartActorRef: {} as Actor<typeof CartMachine>,
})

export const XstateProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const cartActorRef = useActorRef(CartMachine)

  return (
    <XStateContext.Provider value={{ cartActorRef }}>
      {children}
    </XStateContext.Provider>
  )
}
