import type { PropsWithChildren } from "react"
import { createContext, useContext, useEffect, useState } from "react"
import type { Hit } from "react-instantsearch-core"
import { InstantSearch, Configure } from "react-instantsearch-core"
import type { SearchClient } from "algoliasearch"
import qs from "qs"
import pick from "lodash/pick.js"
import {
  createQueryString,
  sortLabels,
  getInitialSearchState,
} from "../utils/search"
import type {
  AlgoliaRecord,
  SearchSortOption,
  SearchState,
  SortKey,
  ShopifyProductPDP,
} from "ui"
import { useMatches } from "@remix-run/react"

type ProductCategoryType = "productType"

export type SearchProviderHits = Array<Hit<AlgoliaRecord>>

export type TransformSearchHitsCallback = (
  hits: SearchProviderHits,
) => Promise<{
  hits: SearchProviderHits
  customData?: Record<string, ShopifyProductPDP> | null | undefined
}>

export interface SearchProviderProps {
  searchClient: SearchClient
  sortIndexes: Partial<Record<SortKey, string>>
  defaultSort?: SortKey
  productCategory?: {
    name: string
    type: ProductCategoryType
  }
  hitsPerPage?: number
  transformSearchHits?: TransformSearchHitsCallback
  queryString?: string
  passthroughQueryStringParams?: Array<string>
}

interface SearchContextValue {
  config: SearchProviderProps
  searchState: SearchState
  sortOptions: Array<SearchSortOption>
  defaultSortOption: SearchSortOption
  transformSearchHits?: TransformSearchHitsCallback
}

const SearchContext = createContext({} as SearchContextValue)

// Exported for use by search-related sub-components like SearchSort
// Most config/data is available automatically via the connector HOCs from
// react-instantsearch, but our custom components occasionally need access
// to our own proprietary config that the connector functions don't know about
// (like productCategory, sortOptions, etc.); so we have to pass it through
// our own context
export const useSearchConfig = () => useContext(SearchContext)

export const SearchProvider = ({
  children,
  ...config
}: PropsWithChildren<SearchProviderProps>) => {
  const {
    searchClient,
    sortIndexes,
    defaultSort = "featured",
    productCategory,
    queryString = "",
    passthroughQueryStringParams = [],
    transformSearchHits,
    hitsPerPage = 24,
  } = config
  // Array of full sort option objects to be used by SearchSort components
  const sortOptions = Object.entries(sortIndexes).map((entry) => {
    const [sortKey, algoliaIndexName] = entry as [SortKey, string]

    return {
      id: sortKey,
      label: sortLabels[sortKey],
      value: algoliaIndexName,
    }
  })

  // Current/default full sort option object based on defaultSort from config
  const defaultSortOption =
    sortOptions.find((op: SearchSortOption) => op.id === defaultSort) ||
    sortOptions[0]

  const parsedQS = qs.parse(
    typeof window !== "undefined" ? location.search : queryString,
    {
      ignoreQueryPrefix: true,
    },
  )

  // Parsed query string params which are being "passed through"; i.e. not
  // search-related but needed by consuming codebase; these remain in the
  // "updated" query strings passed to the onSearchStateChange callback and
  // are not discarded
  const parsedQSPassthroughParams = pick(parsedQS, passthroughQueryStringParams)

  const filters = productCategory
    ? `type:"product" AND products.${productCategory.type}:"${productCategory.name}"`
    : ""

  const configureProps = {
    ...(filters && { filters }),
    hitsPerPage,
    clickAnalytics: true,
  }

  const urlSearchState = getInitialSearchState({
    parsedQS,
    passthroughQueryStringParams,
    sortIndexes,
    defaultSortOption,
    filters,
  })

  const [searchState, setSearchState] = useState<SearchState>(urlSearchState)

  useEffect(() => {
    setSearchState(urlSearchState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(urlSearchState)])

  const searchContextValue: SearchContextValue = {
    config,
    searchState,
    sortOptions,
    defaultSortOption,
    transformSearchHits,
  }

  const matches = useMatches()
  const lastMatch = matches?.length ? matches[matches.length - 1] : null
  const onSearchStateChange = lastMatch?.handle?.onSearchStateChange

  return (
    <SearchContext.Provider value={searchContextValue}>
      <InstantSearch
        searchState={urlSearchState}
        searchClient={searchClient}
        indexName={urlSearchState.sortBy}
        onSearchStateChange={(state: SearchState) => {
          // Create updated query string from new search state & relevant data
          const newQueryString = createQueryString({
            searchState: state,
            sortOptions,
            initialIndexName: urlSearchState.sortBy,
            passthroughParams: parsedQSPassthroughParams,
            defaultSortOption,
          })

          // Allow consuming code to manipulate the updated state object and/or
          // perform side effects via callback prop
          if (onSearchStateChange) {
            onSearchStateChange(state, newQueryString)
          }
          setSearchState(state)
        }}
      >
        {/**
         * Add special filters to search state if a product category is specified
         * This cannot be put into searchState.refinementList because it would require a
         * corresponding RefinementList in the UI
         * See InstantSearch.js docs for 'initialUIState' here:
         * https://www.algolia.com/doc/api-reference/widgets/instantsearch/js/#widget-param-initialuistate
         */}
        <Configure {...configureProps} />
        {children}
      </InstantSearch>
    </SearchContext.Provider>
  )
}
