import { format } from "date-fns"
import { convertToCareersUrl } from "~/utils/url"
import { Link } from "ui"
import type { LinkRendererProps, NavigationSection, NavigationLink } from "ui"
import isFridayEST from "~/utils/isFridayEST"
import type { EventItemForHeaderFooterFragment } from "~/generated/contentful"
import type { ContentfulNavigationSection } from "./types"

export const getLinkRenderer = (url = "/", convert = true) => {
  const convertedUrl = convert ? convertToCareersUrl(url) : url
  // eslint-disable-next-line react/display-name
  return (props: LinkRendererProps) => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <Link {...props} to={convertedUrl} />
  )
}

export function contentToFooterSections({
  sections,
  events,
}: {
  sections: Array<ContentfulNavigationSection>
  events: Array<EventItemForHeaderFooterFragment>
}): Array<NavigationSection> {
  return sections?.map((section) => {
    const footerSection = {
      name: section.name,
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      renderLink: ({ url = "/", ...props }) => <Link to={url} {...props} />,
      links: section.linksCollection.items.map((item) => {
        return {
          name: item.name,
          url: item.url,
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          renderLink: ({ url = "/", ...props }) => <Link to={url} {...props} />,
        }
      }),
    }

    if (section.name.toLowerCase().includes("events")) {
      const eventLinks = events
        .reduce((acc, eventLink) => {
          if (eventLink?.title && eventLink.url && eventLink.startDate) {
            acc.push({
              name: eventLink.title,
              url: eventLink.url,
              meta: format(new Date(eventLink.startDate), "MMM. dd, yyyy"),
              renderLink: getLinkRenderer(eventLink.url),
              className: "mb-1",
            })
          }
          return acc
        }, [] as Array<NavigationLink>)
        .slice(0, 4)

      return {
        ...footerSection,
        links: [
          ...eventLinks,
          {
            name: "VIEW ALL EVENTS",
            url: "https://www.ligonier.org/events",
            className:
              "text-primary-600 font-avenir-caps text-2xs tracking-widest",
          },
        ],
      }
    }

    return footerSection
  })
}

export function contentToHeaderSections({
  sections = [],
  events,
}: {
  sections?: Array<ContentfulNavigationSection>
  events: Array<EventItemForHeaderFooterFragment>
}): Array<NavigationSection> {
  const fridayPromoUrl = "/collection/5-friday"

  const hideFridayPromo = !isFridayEST()

  return sections?.map((section) => {
    const navbarSection = {
      name: section.name,
      mobileName: section.mobileName,
      isDefault: section.isDefault,
      renderLink: getLinkRenderer(section.url),
      links: section.linksCollection.items.reduce((links, item) => {
        const previous = links[links.length - 1]

        if (item.type === "Divider" && previous) {
          previous.divider = true

          return links
        }

        const path = item.url.includes("http")
          ? new URL(item.url).pathname
          : item.url

        if (hideFridayPromo && path === fridayPromoUrl) {
          // if it's not Friday and we are on a friday promotion item, just return links
          return links
        }

        links.push({
          name: item.name,
          renderLink: getLinkRenderer(item.url),
        })

        return links
      }, [] as Array<NavigationLink>),
    }

    if (section.name.toLowerCase().includes("store")) {
      return {
        ...navbarSection,
        promotionTitle: section.promotionTitle,
        promotionSubtitle: section.promotionSubtitle,
        promotionProduct: {
          renderLink: getLinkRenderer(
            `https://store.ligonier.org/${section.promoProduct?.handle}`,
          ),
          product: { title: section.promoProductCollection?.title },
        },
      }
    }

    if (section.name.toLowerCase().includes("events")) {
      return {
        ...navbarSection,
        links: events.reduce((acc, event) => {
          if (event?.title && event.url && event.startDate) {
            acc.push({
              name: event.title,
              url: event.url,
              meta: format(new Date(event.startDate), "MMM. dd, yyyy"),
              renderLink: getLinkRenderer(event.url),
              className: "mb-2",
            })
          }
          return acc
        }, [] as Array<NavigationLink>),
      }
    }

    return navbarSection
  })
}
