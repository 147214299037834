import type { LinksFunction, LoaderFunction } from "@vercel/remix";
import { json } from "@vercel/remix"; // or cloudflare/deno
import {
  Links,
  LiveReload,
  Meta,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError } from
"@remix-run/react";
import { ExternalScripts } from "remix-utils";
import { withSentry } from "@sentry/remix";
import type { ContentfulAlert, NavigationSection } from "ui";
import { contentToFooterSections } from "@utils/contentful/navigationHelpers";
import { fetchAlerts } from "./utils/contentful/alerts";
import stylesheet from "./tailwind.css";
import { authenticator } from "./utils/auth.server";
import type { OAuth2Profile } from "./utils/AzureAADOAuth2BaseStrategy";
import type { EventItemForHeaderFooterFragment } from "./generated/contentful";
import Page from "./components/Page";
import { LDFeatureFlagProvider } from "./providers/LDFeatureFlagProvider";
import { getEventDataForHeaderFooter } from "./utils/contentful/getEventDataForHeaderFooter";
import { getHeaderData } from "./utils/contentful/getHeaderData";
import { getFooterData } from "./utils/contentful/getFooterData";
import type { ContentfulSubfooter } from "./utils/contentful/subfooter";
import { fetchSubfooter } from "./utils/contentful/subfooter";
import { Layout } from "./components/Layout";
import { analytics as ClientAnalytics } from "./utils/segment.client";
import { useEffect, useRef } from "react";
import { XstateProvider } from "./contexts/xStateContext";
import { Analytics } from "@vercel/analytics/react";
import type { ContentfulNavigationSection } from "./utils/contentful/types";

export interface RootLoaderData {
  ENV: Record<string, string | undefined>;
  footerSections: Array<NavigationSection>;
  headerNavigationSections: Array<ContentfulNavigationSection>;
  alerts: {
    site: Array<ContentfulAlert>;
  };
  events: Array<EventItemForHeaderFooterFragment>;
  user: OAuth2Profile | null;
  subfooter: ContentfulSubfooter;
}

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return (
    <html lang="en" className="h-full">
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Page>
          <h1>Oh no!</h1>
          <p>Something went wrong.</p>
        </Page>
        <Scripts />
      </body>
    </html>);

}

export const links: LinksFunction = () => [
{ rel: "icon", href: "/favicon.ico", sizes: "32x32" },
{ rel: "icon", href: "/icon.svg", type: "image/svg+xml" },
{ rel: "apple-touch-icon", href: "/apple-touch-icon.png" },
{ rel: "manifest", href: "/manifest.webmanifest" },
{ rel: "stylesheet", href: stylesheet }];


export const loader: LoaderFunction = async ({ request }) => {
  const footerNavigationSections = await getFooterData();
  const user = await authenticator.isAuthenticated(request);

  const events = await getEventDataForHeaderFooter();
  const footerSections = contentToFooterSections({
    sections: footerNavigationSections || [],
    events: events || []
  });
  const alerts = await fetchAlerts();
  const headerNavigationSections = await getHeaderData();
  const subfooterData = await fetchSubfooter();
  const updatedSubfooterSections = {
    items: user?.isMinistryPartner ?
    [subfooterData.sectionsCollection.items[1]] :
    subfooterData.sectionsCollection.items
  };
  const subfooter = {
    ...subfooterData,
    sectionsCollection: updatedSubfooterSections,
    isMinistryPartner: user?.isMinistryPartner
  };

  return json<RootLoaderData>({
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
      ACCOUNT_REDIRECT_URL: process.env.PUBLIC_ACCOUNT_REDIRECT_URL,
      ALGOLIA_API_KEY: process.env.PUBLIC_ALGOLIA_API_KEY,
      ALGOLIA_APP_ID: process.env.PUBLIC_ALGOLIA_APP_ID,
      ALGOLIA_ENV: process.env.PUBLIC_ALGOLIA_ENV,
      HIDE_ALERT_BANNERS: process.env.PUBLIC_HIDE_ALERT_BANNERS,
      PUBLIC_LD_CLIENTSIDE_ID: process.env.PUBLIC_LD_CLIENTSIDE_ID,
      PUBLIC_SEGMENT_WRITE_KEY: process.env.PUBLIC_SEGMENT_WRITE_KEY,
      PUBLIC_SENTRY_DSN: process.env.PUBLIC_SENTRY_DSN,
      PUBLIC_SENTRY_ENVIRONMENT: process.env.PUBLIC_SENTRY_ENVIRONMENT,
      PUBLIC_SHOPIFY_STOREFRONT_URL: process.env.PUBLIC_SHOPIFY_STOREFRONT_URL,
      PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN:
      process.env.PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN
    },
    headerNavigationSections: headerNavigationSections || [],
    events: events || [],
    alerts,
    footerSections,
    user,
    subfooter
  });
};

function App() {
  const {
    ENV,
    alerts,
    footerSections,
    events,
    headerNavigationSections,
    subfooter,
    user
  } = useLoaderData<typeof loader>();
  const { pathname } = useLocation();
  const identifiedCalled = useRef(false);
  // segment identify
  if (user && !identifiedCalled.current && ClientAnalytics) {
    const userId = user.userId;
    ClientAnalytics.identify(userId, {
      first_name: user.firstName || "",
      last_name: user.lastName,
      email: user.email || ""
    });
    identifiedCalled.current = true;
  }

  useEffect(() => {
    ClientAnalytics.page({
      path: pathname,
      url: window.location.href
    });
  }, [pathname]);

  const LDFeatureFlagProviderComp = LDFeatureFlagProvider(
    ENV.PUBLIC_LD_CLIENTSIDE_ID
  );

  useEffect(() => {
    let oneTrustScript1: HTMLScriptElement, oneTrustScript2: HTMLScriptElement;
    if (ENV.NODE_ENV === "production") {
      oneTrustScript1 = document.createElement("script");
      oneTrustScript1.setAttribute(
        "src",
        "https://cdn.cookielaw.org/consent/0e9823a1-265c-4b41-863d-aa7295843960.js"
      );
      oneTrustScript2 = document.createElement("script");
      oneTrustScript2.innerHTML = `function OptanonWrapper(){}`;

      document.head.appendChild(oneTrustScript1);
      document.head.appendChild(oneTrustScript2);
    }

    return () => {
      if (oneTrustScript1) document?.head?.removeChild(oneTrustScript2);
      if (oneTrustScript2) document?.head?.removeChild(oneTrustScript1);
    };
  }, [ENV.NODE_ENV]);

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Analytics />
        <LDFeatureFlagProviderComp>
          <XstateProvider>
            <Layout
              footerSections={footerSections}
              events={events}
              headerNavigationSections={headerNavigationSections}
              alerts={alerts}
              ENV={ENV}
              user={user}
              subfooter={subfooter} />

          </XstateProvider>
        </LDFeatureFlagProviderComp>
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
        <LiveReload />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />

      </body>
    </html>);

}

export default withSentry(App);