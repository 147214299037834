import { withLDProvider } from "launchdarkly-react-client-sdk"
import type { FunctionComponent, PropsWithChildren, ReactNode } from "react"

const Wrapper: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <>{children}</>
)

export const LDFeatureFlagProvider = (clientSideID: string) => {
  return withLDProvider<Record<string | symbol | number, ReactNode>>({
    clientSideID,
  })(Wrapper)
}
